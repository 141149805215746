import React, { FC, useCallback, useContext, useState } from 'react'
import { ScreenContext } from 'react-components'

import { DatePickerExclusiveProps, DatePickerPassThroughProps, DatePickerRenderedComponentAddedProps } from './types'
import ModalCalendar from './modal-calendar'
import { firstDayOfWeek } from './constants'
import { useCalendarObserver, useDatePicker } from './hooks'
import { getDatePrompt } from './utils'
import { useTranslation } from '../../../hooks/locale'

type Props = DatePickerRenderedComponentAddedProps &
    DatePickerPassThroughProps & {
        component: DatePickerExclusiveProps['modalComponent']
        calendarContainerComponent: DatePickerExclusiveProps['modalCalendarContainerComponent']
    }

const _intersectionObserverOptions = {
    root: null,
    threshold: 0.25,
}

const Modal: FC<Props> = ({
    component: Component,
    calendarContainerComponent,
    startDate: initialStartDate,
    endDate: initialEndDate,
    onStartDateChange,
    onEndDateChange,
    datePrompt,
    ...restProps
}) => {
    const {
        datePickerType,
        calendarMonths,
        setCalendarMonths,
        trackStartDateChange,
        trackEndDateChange,
        disabledByDefault,
        enabledDatesSet,
        currentlyHoveredDate,
        setCurrentlyHoveredDate,
        loading,
    } = restProps

    const [startDate, setStartDate] = useState(initialStartDate)
    const [endDate, setEndDate] = useState(initialEndDate)

    const { isMobile } = useContext(ScreenContext)
    const { t } = useTranslation()

    const setUpdatedStartDate = useCallback(
        (date?: Date | null) => {
            setStartDate(date ? date : null)
            onStartDateChange && onStartDateChange(date ? date : null)
        },
        [onStartDateChange],
    )

    const setUpdatedEndDate = useCallback(
        (date?: Date | null) => {
            setEndDate(date)
            onEndDateChange && onEndDateChange(date ? date : null)
        },
        [onEndDateChange],
    )

    const _handleClear = useCallback(() => {
        setUpdatedStartDate(null)
        setUpdatedEndDate(null)
    }, [])

    const _handleReset = useCallback(() => {
        setUpdatedStartDate(initialStartDate)
        setUpdatedEndDate(initialEndDate)
    }, [initialEndDate, initialStartDate])

    const _handleSubmit = useCallback(() => {
        onStartDateChange(startDate)
        onEndDateChange?.(endDate || null)
    }, [startDate, endDate, onStartDateChange, onEndDateChange])

    const { intersectionTargetRef: _endOfCalendarMonthsRef } = useCalendarObserver({
        setCalendarMonths,
        intersectionObserverOptions: _intersectionObserverOptions,
    })

    const { onSelect, showRange } = useDatePicker({
        startDate,
        endDate,
        datePickerType,
        onStartDateChange: setUpdatedStartDate,
        onEndDateChange: setUpdatedEndDate,
        trackStartDateChange,
        trackEndDateChange,
    })

    // Date prompt needs to be calculated here as it is dependent on the temporary `endDate` created by the modal.
    const _datePrompt = datePrompt ?? getDatePrompt(datePickerType, startDate, endDate || currentlyHoveredDate, true, t)

    return (
        <Component
            {...restProps}
            datePrompt={_datePrompt}
            onClear={_handleClear}
            onReset={_handleReset}
            onSubmit={_handleSubmit}
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setUpdatedStartDate}
            onEndDateChange={setUpdatedEndDate}
        >
            <>
                {calendarMonths.map(month => (
                    <ModalCalendar
                        key={month.toISOString()}
                        component={calendarContainerComponent}
                        month={month}
                        showMonthInBody
                        showDaysOfWeekGrid={!isMobile}
                        firstDayOfWeek={firstDayOfWeek}
                        startDate={startDate}
                        endDate={endDate}
                        showRange={showRange}
                        currentlyHoveredDate={currentlyHoveredDate}
                        setCurrentlyHoveredDate={setCurrentlyHoveredDate}
                        onSelect={onSelect}
                        disabledByDefault={disabledByDefault}
                        enabledDates={enabledDatesSet}
                        loading={loading}
                    />
                ))}
                {/* Empty div that is used as a marker to denote that we've reached the end of the calendar months list,
                as suggested here: https://stackoverflow.com/a/59398440/8158281 */}
                <div ref={_endOfCalendarMonthsRef} />
            </>
        </Component>
    )
}

export default Modal
